import { CLink } from '@/CLink';
import { SecondaryBtn } from '@/buttons';
import { ReactComponent as CalendarIcon } from '@a/icons/calendar.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment, useEffect, useRef, useState } from 'react';
import { DesktopNav, NavItem } from './DesktopNavPrimitive';
import { MobNav } from './MobNavD';
import { getNavLink, navLinks, siteInfo } from './NavLinks';
import { theme } from './styles/GlobalStyles';
import { flexCenter, flexSpace, px } from './styles/classes';

const Navbar = styled.header<{ scrolled: boolean }>`
    ${px};
    padding-top: 12px;
    padding-bottom: 12px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-right: var(--removed-body-scroll-bar-size);
    z-index: 100;
    background-color: ${({ theme }) => theme.colors.gray6};
    transition: background-color 0.3s ease-in-out;

    > div {
        ${flexSpace};
        max-width: 1720px;
        margin: 0 auto;

        > div {
            ${flexCenter};
            justify-content: flex-end;
        }
    }

    @media (min-width: 1024px) {
        padding-top: 16px;
        padding-bottom: 16px;
        background-color: transparent;
    }

    ${({ scrolled, theme }) =>
        scrolled &&
        css`
            background-color: ${theme.colors.gray6} !important;
        `}
`;

const HomeLink = styled(CLink)`
    transition: opacity 0.3s ease-in-out;
    flex-shrink: 0;

    :hover {
        opacity: 0.7;
    }

    :focus-visible {
        opacity: 0.7;
    }

    @media (max-width: 1023px) {
        > .gatsby-image-wrapper {
            width: 72px;
            height: 47px;
        }
    }
`;

const DesktopNavMenu = styled(DesktopNav)`
    width: 100%;
    display: none;

    @media (min-width: 1024px) {
        display: block;
    }
`;

const menuStyles = css`
    > li {
        position: relative; // for submenu align with item

        :first-of-type {
            margin-left: auto;
        }

        :nth-last-of-type(2) {
            margin-right: auto;

            > a {
                margin-right: 0;
            }
        }
    }
`;

const contentStyles = css`
    > ul {
        border-radius: 10px;
        border: 1px solid ${theme.colors.gray2};
        background-color: ${theme.colors.gray1};
        box-shadow:
            0px 4.006px 6.773px 0px rgba(109, 106, 93, 0.03),
            0px 8.278px 15.969px 0px rgba(109, 106, 93, 0.04),
            0px 18.532px 39.563px 0px rgba(109, 106, 93, 0.04),
            0px 51px 125px 0px rgba(109, 106, 93, 0.09);
    }
`;

const PhoneBtn = styled(CLink)`
    ${flexCenter};
    margin-left: auto;
    margin-right: 32px;

    > svg {
        path {
            fill: ${({ theme }) => theme.colors.blue3};
        }
    }

    @media (min-width: 1024px) {
        margin-right: 0;
        margin-left: 20px;
        width: 56px;
        height: 56px;
        background-color: ${({ theme }) => theme.colors.gray1};
        border-radius: 10px;
        flex-shrink: 0;
        transition: background-color 0.3s ease-in-out;

        > svg {
            path {
                fill: ${({ theme }) => theme.colors.blue5};
            }
        }

        :hover {
            background-color: ${({ theme }) => theme.colors.blue1};
        }

        :focus-visible {
            background-color: ${({ theme }) => theme.colors.blue1};
        }
    }
`;

export const HomeLogo = () => (
    <HomeLink to="/">
        <StaticImage
            src="../../../assets/images/layout/logo.png"
            alt="city space constructors"
            width={145}
            height={96}
            loading="eager"
        />
    </HomeLink>
);

// link can be /meet-the-doctors/#dr-herbert or #dr-herbert
export const scrollToSection = (link: string) => {
    const fullLink = typeof link === 'string' ? link.split('#') : '';
    const element = document.querySelector(`#${fullLink[1] || ''}`);
    if (!element) {
        navigate(fullLink[0], {
            state: {
                tag: `#${fullLink[1] || ''}`,
            },
        });
    }
    const y = element && element.getBoundingClientRect().top + window.scrollY - 76;
    window.scroll({
        behavior: 'smooth',
        top: y || 0,
    });
};

const Observe = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    top: 10px;
    left: 0;
`;

export const Nav = () => {
    const [allDialogsClosed, setAllDialogsClosed] = useState(false);
    const closeAllDialogs = () => setAllDialogsClosed(open => !open);

    const [scrolled, setScrolled] = useState(false);

    const observeRef = useRef<HTMLElement | null>(null);

    const visible = useIntersectionObserver(observeRef, {});

    useEffect(() => {
        const timeout = setTimeout(() => {
            setScrolled(!visible);
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [visible]);

    return (
        <Fragment>
            <Navbar scrolled={scrolled}>
                <div>
                    <HomeLogo />
                    <DesktopNavMenu
                        menuStyles={menuStyles}
                        contentStyles={contentStyles}
                        links={navLinks.slice(0, -1)}
                    >
                        <NavItem>
                            <SecondaryBtn
                                onClick={() => scrollToSection(getNavLink('contact us'))}
                                white
                            >
                                <CalendarIcon /> contact us
                            </SecondaryBtn>
                        </NavItem>
                    </DesktopNavMenu>
                    <PhoneBtn
                        to={siteInfo.phone.link}
                        aria-label={`call us at ${siteInfo.phone.text}`}
                    >
                        <PhoneIcon />
                    </PhoneBtn>
                    <MobNav allDialogsClosed={allDialogsClosed} closeAllDialogs={closeAllDialogs} />
                </div>
            </Navbar>
            <Observe ref={observeRef} />
        </Fragment>
    );
};
